import { BaseDto } from "@/shared/dtos/base-dto";
import { tarea } from "../tareas/tarea";
import { tipo_plantilla } from "./tipo_plantilla";

export class plantillas_cabezera extends BaseDto {
  public nombre!: string;
  public id_tipo_platilla!: number;
  public id_periodicidad!: number;
  public tipo_plantilla!: tipo_plantilla;
  public tareas_asociadas!: tarea[];
}
