



















































import { RouterNames } from "@/router/routernames";
import { plantillas_cabezera } from "@/shared/dtos/platillas/plantillas_cabezera";
import { MessageService } from "@/shared/services/message-service";
import plantillas_cabezeraModule from "@/store/modules/plantillas_cabezera-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class PlantillasTareasLista extends Vue {
  private dialog_add_new: boolean = false;
  private new_platilla: plantillas_cabezera = new plantillas_cabezera({
    nombre: "",
  });
  nuevo() {
    this.cancelar_new();
    this.dialog_add_new = true;
  }
  aceptar_new() {
    plantillas_cabezeraModule
      .guardarplantillas_cabezera(this.new_platilla)
      .then((x: plantillas_cabezera) => {
        this.abrir_formulario(x.id);
      });
  }
  cancelar_new() {
    this.new_platilla.nombre = "";
    this.dialog_add_new = false;
    this.new_platilla.id = 0;
    this.new_platilla.id_tipo_platilla = 1;
  }
  editar(obj: plantillas_cabezera) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  eliminar(obj: plantillas_cabezera) {
    plantillas_cabezeraModule
      .eliminarplantillas_cabezera(new plantillas_cabezera(obj))
      .then(() => {
        //@ts-ignore
        this.$refs["data_table"].refresh();
      })
      .catch(() => {
        MessageService.toast(
          "No se ha podido eliminar, hay datos asociados",
          MessageService.TypeWarning
        );
      });
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.administracion_tareas_plantillas_formulario,
      params: { id: id },
    });
  }
  Execute_Plantillas() {
    plantillas_cabezeraModule.getplantillas_cabezeras_ActionExecuteTrigger();
  }
  Execute_Plantilla(item: plantillas_cabezera) {
    plantillas_cabezeraModule.getplantillas_cabezera_ActionExecuteTrigger(
      item.id
    );
  }
}
