import { store } from "@/store/store";
import { plantillas_cabezera } from "@/shared/dtos/platillas/plantillas_cabezera";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "plantillas_cabezeraModule",
  store,
  dynamic: true,
})
class plantillas_cabezeraModule extends VuexModule {
  public plantillas_cabezeras: plantillas_cabezera[] = [];
  public plantillas_cabezera: plantillas_cabezera = new plantillas_cabezera();

  @Action({ commit: "onGetplantillas_cabezeras" })
  public async getplantillas_cabezeras() {
    return await ssmHttpService.get(API.plantillas_cabezera);
  }

  @Action({ commit: "onGetplantillas_cabezera" })
  public async getplantillas_cabezera(id: any) {
    return await ssmHttpService.get(API.plantillas_cabezera + "/" + id);
  }

  @Action({ commit: "onGetplantillas_cabezeras" })
  public async getplantillas_cabezeras_ActionExecuteTrigger() {
    return await ssmHttpService.get(API.plantillas_cabezera+ "/ExecutePlantillas");
  }

  @Action({ commit: "onGetplantillas_cabezera" })
  public async getplantillas_cabezera_ActionExecuteTrigger(id: any) {
    return await ssmHttpService.get(API.plantillas_cabezera + "/ExecutePlantillas/" + id);
  }


  @Action
  public async guardarplantillas_cabezera(
    plantillas_cabezera: plantillas_cabezera
  ) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(
      API.plantillas_cabezera,
      plantillas_cabezera.toJson()
    );
  }

  @Action
  public async modificarplantillas_cabezera(
    plantillas_cabezera: plantillas_cabezera
  ) {
    return await ssmHttpService.put(
      API.plantillas_cabezera + "/" + plantillas_cabezera.id,
      plantillas_cabezera
    );
  }

  @Action
  public async eliminarplantillas_cabezera(
    plantillas_cabezera: plantillas_cabezera
  ) {
    return await ssmHttpService.delete(
      API.plantillas_cabezera + "/" + plantillas_cabezera.id,
      null,
      false
    );
  }

  @Mutation
  public onGetplantillas_cabezeras(res: plantillas_cabezera[]) {
    this.plantillas_cabezeras = res
      ? res.map((x) => new plantillas_cabezera(x))
      : [];
  }

  @Mutation
  public onGetplantillas_cabezera(res: plantillas_cabezera) {
    this.plantillas_cabezera = new plantillas_cabezera(res);
  }
}
export default getModule(plantillas_cabezeraModule);
